.about--outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 164px;
  color: white;
  font-size: 24px;
  gap: 20px;
  background-image: url("../../images/aboutBackground.webp");
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 768px) {
    clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
  }
  h2 {
    font-size: 72px;
    font-weight: 700;
    color: white;
    margin-bottom: 32px;
    padding-top: 94px;

    @media screen and (max-width: 768px) {
      font-size: 52px;
      font-weight: 500;
    }
  }

  p {
    font-size: 36px;
    text-shadow: 0 0 6px black;
    @media screen and (max-width: 768px) {
      font-size: 24px;
      text-align: left;
    }
  }
}

.about--text-container {
  max-width: 800px;
  width: 80%;
  text-align: left;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 0 20px;
  padding-bottom: 120px;

  li {
    padding: 4px 0;
    @media screen and (max-width: 768px) {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

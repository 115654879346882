@import "../../global/styles.scss";

.navigation--outer-container {
  height: 70px;
  background-color: $black;
  display: flex;
  justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    color: white;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, $blakeRed, $typeScriptBlue) 2;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-size: 24px;
  position: sticky;
  top: 0;
  z-index: 12;
}

a {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.navigation--nav-link {
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    padding: 0 6px;
  }
}

.navigation--nav-container {
  height: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
    gap: 20px;
    width: fit-content;
    li {
      height: 100%;
    }
}
@import "../../../global/styles.scss";

.project-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  height: 320px;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 8px;
  border: 5px solid white;
  @media  (max-width: 768px) {
    height: 640px;
    flex-direction: column;
  }
}
.project--website-photo {
  width: 33%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 90% 0%, 100% 100%, 0% 100%);

  @media  (max-width: 768px) {
    width: 100%;
    height: 33%;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  
  }
}

.skills {
  height: 100%;
  width: 67%;
 margin: 20px;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;

  @media  (max-width: 768px) {
    width: 100%;
  }

}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  img {
    height: 64px;
    width: auto;
    @media  (max-width: 480px) {
      height: 32px;
    }
  }

  p {
    text-align: center;
  }
}

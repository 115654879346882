.contact--outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  font-size: 24px;
  gap: 20px;
  padding-bottom: 24px;

  @media screen and (max-width: 768px) { 
    padding-top: 32px;
  }

  h2 {
    font-size: 72px;
    font-weight: 700;
    color: white;
    margin-bottom: 32px;

    @media screen and (max-width: 768px) {
      font-size: 52px;
      font-weight: 500;
    }
  }

  p {
    font-size: 36px;
    text-shadow: 0 0 6px black;

    @media screen and (max-width: 768px) {
        font-size: 18px;
        text-align: left;
      }
  }
}

.contact--text-container {
  max-width: 800px;
  width: 80%;
  text-align: center;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 0 20px;
  padding-bottom: 120px;
  text-wrap: wrap;
  ul {
    padding-top: 32px;
  }

  li {
    padding: 4px 0;
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    font-size: 18px;
    font-weight: 500;
    a {
        text-wrap: wrap;
    }
  }
}

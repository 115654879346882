@import "../../../global/styles.scss";

.introduction--outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  color: white;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  position: relative;

  @media screen and (max-width: 768px) {
    padding-bottom: 64px;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  }
}

.introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding-top: 200px;
  margin-top: 64px;
  height: calc(100vh - 70px);
  text-align: center;
  z-index: 2;

  @media screen and (max-width: 768px) {
    padding-top: 64px;
  }

  h1 {
    font-size: 200px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      font-size: 96px;
      font-weight: 500;
    }
  }
  h2 {
    font-size: 72px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      font-size: 52px;
      font-weight: 500;
    }
  }
  p {
    font-size: 36px;
    font-weight: 400;
    @media screen and (max-width: 768px) {
      font-size: 24px;
      font-weight: 300;
    }
  }
}

.introduction--animation-container {
  max-width: 500px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    width: 50%;
    padding: 20px 0;
  }
}

.text-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0 24px;
  cursor: default;
  text-shadow: 0 0 5px rgb(0, 0, 0);

  strong {
    font-weight: 700;
  }
}

.introduction--download {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.home--image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: calc(100% - 120px);
  height: calc(100vh - 70px);
  svg {
    width: 100%;
    height: auto;
  }
}

.introduction--button {
  --r: 40px;

  height: 48px;
  width: 100px;
  border-radius: var(--r);
  position: relative;
  transform: translate(0);
  transform-style: preserve-3d;
  border: 2px solid white;
  font-size: 30px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
}

.introduction--button sh {
  position: absolute;
  inset: -64px;
  border: 64px solid #fcfcfc00;
  border-radius: calc(64px + var(--r));
  transform: translateZ(-1px);
  -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.rainbow sh:before {
  content: "";
  position: absolute;
  inset: -5px;
  transform: translate(0px, 0px);
  background: conic-gradient(
    from 90deg at 40% -50%,
    #ffd700,
    #f79d03,
    #ee6907,
    #e6390a,
    #de0d0d,
    #d61039,
    #cf1261,
    #c71585,
    #cf1261,
    #d61039,
    #de0d0d,
    #ee6907,
    #f79d03,
    #ffd700,
    #ffd700,
    #ffd700
  );
  filter: blur(5px);
  border-radius: var(--r);
}
